
import { api } from "@/api/api";
import {
  ApiGetCourseDefaultOptionDto,
  ApiGetCourseParticipantKursAdminDto,
  ApiGetCustomerDto,
  ApiGetCustomerPersonDto,
  ApiGetGuestEmployeewithMemberOrganizationDto,
  ApiGetMemberOrganizationDto,
  ApiGetMemberOrganizationFeatureSettingDto,
  ApiIdRankDto,
} from "@/api/generated/Api";
import UpsertParticipantModal from "@/components/course/details/UpsertParticipantModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { CourseParticipantType } from "@/shared/enums/courseParticipantType.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { getCustomerDtoRef } from "@/shared/helpers/courseHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { formatDate, formatDateShort, formatDateTime, formatRelative } from "@/shared/helpers/dateHelpers";
import { getOrderedMemberOrganizations } from "@/shared/helpers/getters";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import isBefore from "date-fns/isBefore";
import { SortableEvent } from "sortablejs";
import { VSwitch } from "vuetify/lib";
import { mapGetters } from "vuex";

export interface MemberOrganization {
  id: number | null;
  name?: string | null;
}

export interface ActiveUser {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  birthDate?: string | null;
}

export default defineComponent({
  name: "ParticipantsTable",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    UpsertParticipantModal,
    BaseModal,
    BaseConfirmModalForm,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const restrictedAccessApi = useRestrictedAccessApi();
    const showModal = ref(false);
    const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
    const courseStudents = ref<ApiGetCourseParticipantKursAdminDto[]>([]);
    const courseDefaultOptions = ref<ApiGetCourseDefaultOptionDto>({});
    const contacts = ref<(ApiGetCustomerDto | ApiGetCustomerPersonDto)[]>([]);
    const orderedMemberOrganizations = ref<MemberOrganization[]>();
    const deleteParcipantModalData = ref(getInitialModalData());
    const activeFeatureSettings = ref<ApiGetMemberOrganizationFeatureSettingDto[]>([]);
    const currentGuestUser = ref<ApiGetGuestEmployeewithMemberOrganizationDto>();
    const userBypassPermission = ref<boolean>(false);
    const canEditParticpantCheck = ref<boolean>(false);
    const textTooltip = ref<string>();

    // Check if user is a part of subOrganizer, if so they can change the status from preapproved to approved etc
    const getCurrentUser = async () => {
      if (!hasMemberOrgAccess) {
        return;
      }

      let topLevelId = 0;
      currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
      const memberOrganizations = (await api.guestside.getGuestUserMemberOrganizations()).data;
      let isRoot = false;

      if (memberOrganizations && memberOrganizations?.length > 0) {
        const myOrg = memberOrganizations.find((x: any) => x.id === currentGuestUser.value?.memberOrganization?.id);
        if (myOrg?.parentId === undefined) {
          isRoot = true;
          topLevelId = myOrg!.id;
        } else {
          const isMyOrgParent: boolean = memberOrganizations.some((x: any) => x.parentId == myOrg.id);
          if (isMyOrgParent) {
            isRoot = false;
            topLevelId = myOrg!.parentId!;
          } else {
            isRoot = false;
            topLevelId = memberOrganizations.find((x: any) => x.id === myOrg.parentId)!.parentId!;
          }
        }
      } else {
        topLevelId = currentGuestUser!.value!.memberOrganization!.id!;
      }

      checkGuestUserTopLevelMemberOrganizationFeaturesSettings(topLevelId, isRoot);
    };

    const checkGuestUserTopLevelMemberOrganizationFeaturesSettings = async (topLevelId: number, isRoot: boolean) => {
      if (!hasMemberOrgAccess) {
        return;
      }
      if (!topLevelId) {
        return;
      }
      if (isRoot || topLevelId === props.course.organizerOrganizationId) {
        if (checkFeatureSettings(topLevelId)) {
          userBypassPermission.value = true;
          canEditParticipant();
          getEditParticipantLabel();
        }
      }
    };

    // Guest users can only edit after enrollment deadline is due and
    // participant has enrollment status "søkt"
    // Unless the guest user has bypassEnrollmentDeadline feature setting activated for their member organization
    const canEditParticipant = async function () {
      if (!hasMemberOrgAccess) {
        canEditParticpantCheck.value = true;
        return;
      }

      if (hasMemberOrgAccess && userBypassPermission.value) {
        canEditParticpantCheck.value = true;
        return;
      }

      if (isBefore(new Date(props.course.enrollmentDeadline), new Date())) {
        canEditParticpantCheck.value = true;
      }
    };

    const getActiveFeatureSettings = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (hasMemberOrgAccess) {
          activeFeatureSettings.value = (await api.guestside.getActivatedMemberOrganizationFeatureSettings1()).data;
        }

        if (!hasMemberOrgAccess) {
          activeFeatureSettings.value = (await api.organization.getActivatedMemberOrganizationFeatureSettings()).data;
        }
      });
    };

    const checkFeatureSettings = (id: number) => {
      if (!id) {
        return false;
      }

      const memberOrganization = activeFeatureSettings.value.find((x) => x.organizationId === id);

      if (memberOrganization && memberOrganization.bypassEnrollmentDeadlineInGuestPortal) {
        return true;
      }

      return false;
    };

    const getEditParticipantLabel = () => {
      textTooltip.value = isVocationalSchool(store.state.plans.studyplan.mainCourseId)
        ? "Rediger student"
        : "Rediger deltaker";

      if (hasMemberOrgAccess && !userBypassPermission.value) {
        if (!isBefore(new Date(props.course.enrollmentDeadline), new Date())) {
          textTooltip.value = "Kan ikke redigere deltaker før påmeldingsfrist er gått ut";
        }
      }
    };

    const ranking = computed(
      () => (item: ApiGetCourseParticipantKursAdminDto) => courseStudents.value.indexOf(item) + 1
    );

    const loadMemberOrganizations = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        memberOrganizations.value = (await restrictedAccessApi.getMemberOrganizations()).data.sort(
          localeSortByProperty("name")
        );
      });
    };

    const activeUsers = computed(() => {
      const filteredActiveUsers = contacts.value.filter((currentUser) => currentUser.isActive);
      return filteredActiveUsers.reduce<ActiveUser[]>(
        (previous, current: ApiGetCustomerDto | ApiGetCustomerPersonDto) => {
          const currentRef = getCustomerDtoRef(current);
          const activeUser = {
            id: current.id,
            firstName: currentRef.firstName,
            lastName: currentRef.lastName,
            email: current.email,
            birthDate: formatDate(currentRef.birthDate),
          };
          return [...previous, activeUser];
        },
        []
      );
    });

    const setOrderedMemberOrganizations = async () =>
      (orderedMemberOrganizations.value = await getOrderedMemberOrganizations());

    const sorted = (event: SortableEvent) => {
      const { newIndex, oldIndex } = event;
      if (newIndex === undefined || oldIndex === undefined) {
        return;
      }
      const element = courseStudents.value[oldIndex];
      courseStudents.value.splice(oldIndex, 1);
      courseStudents.value.splice(newIndex, 0, element);
    };

    const updateParticipantRank = async (courseParticipants: ApiGetCourseParticipantKursAdminDto[]) => {
      const studentsRanked: ApiIdRankDto[] = courseParticipants.map((student, index) => ({
        userId: student.userId,
        rank: index,
      }));
      await api.course.updateCourseParticipantRanksAsync(+route.params.id, studentsRanked);
      openNotification(store, NotificationItemType.Success, "Rangering oppdatert");
    };

    const getMemberOrganizationNameById = (id?: number) => {
      if (id === undefined) {
        return "Ingen medlemsorganisasjon";
      }
      return memberOrganizations.value?.find((office) => office.id === id)?.name || "Ukjent navn";
    };

    const getDefaultOptions = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const defaultOptions = (await restrictedAccessApi.getDefaultOptions()).data;
        courseDefaultOptions.value = defaultOptions;
      });
    };

    const getContacts = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const customerPersons = (await restrictedAccessApi.getCustomerPersons()).data;
        contacts.value = customerPersons;
      });
    };

    const getCourseParticipants = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = (
          await restrictedAccessApi.getCourseParticipants(+route.params.id, {
            RoleNames: [CourseParticipantType.Student],
            IsActive: true,
          })
        ).data;

        courseStudents.value = response;
      });
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const openDeleteParticpant = useOpenModal(
      ModalType.Delete,
      isVocationalSchool(store.state.plans.studyplan.mainCourseId) ? "student" : "deltaker",
      deleteParcipantModalData
    );

    const deleteParticipant = async (item: ApiGetCourseParticipantKursAdminDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.course.deleteCourseParticipantByCourseIdAsync(+route.params.id, item.userId);
          openNotification(store, NotificationItemType.Success, "Fjerning fullført");
        } catch (e: any) {
          e.response.data.fieldsErrors?.forEach((error: any) => {
            openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${error.error}`);
          });
        } finally {
          await getCourseParticipants();
          deleteParcipantModalData.value.showModal = false;
        }
      });
    };

    onMounted(async () => {
      await Promise.all([
        getActiveFeatureSettings(),
        loadMemberOrganizations(),
        getCourseParticipants(),
        getDefaultOptions(),
        getContacts(),
        setOrderedMemberOrganizations(),
        getCurrentUser(),
        canEditParticipant(),
        getEditParticipantLabel(),
      ]);
    });

    return {
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
      hasMemberOrgAccess,
      sorted,
      orderedMemberOrganizations,
      getMemberOrganizationNameById,
      courseStudents,
      showModal,
      ranking,
      closeModal,
      getCourseParticipants,
      courseDefaultOptions,
      activeUsers,
      updateParticipantRank,
      isCourseDone: computed(() => props.course.status === CourseStatus.Closed),
      formatDateShort,
      getEditParticipantLabel,
      formatDateTime,
      courseId: +route.params.id,
      deleteParticipant,
      openDeleteParticpant,
      deleteParcipantModalData,
      activeFeatureSettings,
      userBypassPermission,
      canEditParticpantCheck,
      textTooltip,
    };
  },
  data() {
    return {
      modalHeadline: "",
      modalType: "",
      search: "",
      selectedUserId: 0,
      chosenMemberOrgId: 0,
    };
  },
  computed: {
    ...mapGetters("plans", ["getStudy"]),
    filter() {
      return [
        {
          component: VSwitch,
          value: "status",
          staticClass: "mx-3 pa-0",
          default: false,
          attrs: {
            label: "Vis venteliste",
            inset: true,
            hideDetails: true,
          },
          apply: (value: any, model: any) => (!model && value != null) || model,
        },
      ];
    },
    headers(props: any) {
      return [
        { text: "Rang.", value: "rank" },
        { text: "Handlinger", value: "actions", sortable: false },
        { text: "Navn", value: "userFullName" },
        { text: "Medlemsorganisasjon", value: "memberOrganizationId" },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Søknadsmetode",
          value: "appliedViaForm",
        },
        {
          text: "Bestiller",
          value: "purchaser.name",
        },
        {
          text: "Fakturamottaker",
          value: "invoiceRecipient.name",
        },
        {
          text: "Brukertype",
          value: "userTypeType",
        },
        {
          text: "Kommentar",
          value: "latestCourseParticipantComment",
        },
        props.course.plan.registerHours
          ? {
              text: "Oppmøteprosent",
              value: "currentAttendancePercentage",
            }
          : {},
        {
          text: "Oppdatert",
          value: "updated",
        },
      ];
    },
  },

  methods: {
    formatRelative,
    newParticipant() {
      this.$store.commit("courseParticipants/SET_PERSON", {});
      this.showModal = true;
      this.modalHeadline = `Legg til ${this.getHeadLineType((this as any).getStudy.mainCourseId)}`;
      this.modalType = "add";
    },
    editParticipant(value: any) {
      this.$store.commit("courseParticipants/SET_PERSON", value);
      this.showModal = true;
      this.modalHeadline = `Rediger ${this.getHeadLineType((this as any).getStudy.mainCourseId)} (${
        value.userFullName
      })`;
      this.modalType = !this.isCourseDone ? "edit" : "display";
      this.selectedUserId = value.userId;
      this.chosenMemberOrgId = value.memberOrganizationId;
    },
    onepager(value: ApiGetCourseParticipantKursAdminDto) {
      this.$store.commit("courseParticipants/SET_PERSON", value);
      this.$router.push({
        path: `/kurset/${this.$route.params.id}/participants/${value.userId}`,
        query: {
          appliedViaForm: value.appliedViaForm.toString(),
          participantMemberOrgId: value.memberOrganizationId?.toString(),
        },
      });
    },
    getHeadLineType(value: number) {
      // TODO : Add Enum for checking mainCourseId
      return isVocationalSchool(value) ? "student" : "deltaker";
    },
  },
});
